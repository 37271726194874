import { createSlice } from '@reduxjs/toolkit';
import { Booking } from 'src/types/apps/booking';

interface StateType {
  bookings: {
    allDay: boolean;
    booking: Booking;
    color: string;
    end: Date; // or string if you prefer
    resourceId?: string; // optional
    start: Date; // or string if you prefer
    title: string;
  }[]
  scheduleConfig: {
    from_date: string,
    to_date: string,
    showAllPitches: boolean,
    coaches?: number[],
    bookingType?: 1 | 2 | -1
  }
  booking: Booking | undefined
}

const initialState: StateType = {
  bookings: [],
  scheduleConfig: {
    from_date: "",
    to_date: "",
    showAllPitches: false
  },
  booking: undefined
};



export const ConfigSlice = createSlice({
  name: 'Schedule',
  initialState,
  reducers: {
    setScheduleConfig: (state: StateType, action) => {
      state.scheduleConfig = {
        ...state.scheduleConfig,
        ...action.payload
      }
    },
    removeScheduleKey: (state: StateType, action) => {
      const newConfig = { ...state.scheduleConfig }
      delete newConfig[action.payload]

      state.scheduleConfig = {
        ...newConfig,
      }
    },
    setBookings: (state: StateType, action) => {
      state.bookings = action.payload
    },
    setBooking: (state: StateType, action) => {
      state.booking = action.payload
    },
    appendBooking: (state: StateType, action) => {
      const bookings = state.bookings;

      if (action?.payload?.booking?.booking_status_label === "Canceled") {
        // .. delete bookin from array
        state.bookings = bookings.filter((item) => item?.booking?.id !== action.payload?.booking?.id);

        return
      }

      const foundBooking = bookings.findIndex(item => item?.booking?.id === action.payload?.booking?.id)

      if (foundBooking !== -1) {
        // .. update booking info
        state.bookings[foundBooking] = action.payload;

        return
      }

      // .. append booking in bookings
      state.bookings = [
        ...state.bookings,
        action.payload
      ]
    },
  },
});

export const {
  setBookings,
  setScheduleConfig,
  setBooking,
  removeScheduleKey,
  appendBooking
} = ConfigSlice.actions;

export default ConfigSlice.reducer;
