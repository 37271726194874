import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RevenueData } from 'src/services/reporting/revenueComparison';
import { StatisticsResponse } from 'src/services/reporting/statistics';

interface StateType {
  statistics: StatisticsResponse | undefined;
  revenueComparison: RevenueData | undefined;
  dateTypeFilter: string;
  selectedPitchId: number | string | any;
}

const initialState: StateType = {
  statistics: undefined,
  revenueComparison: undefined,
  dateTypeFilter: 'this_month',
  selectedPitchId: [],
};

export const ReportingSlice = createSlice({
  name: 'Reporting',
  initialState,
  reducers: {
    setStatistics: (state, action: PayloadAction<StatisticsResponse>) => {
      state.statistics = action.payload;
    },
    setRevenueComparison: (state, action: PayloadAction<RevenueData>) => {
      state.revenueComparison = action.payload;
    },
    setDateTypeFilter: (state, action: PayloadAction<string>) => {
      state.dateTypeFilter = action.payload;
    },
    setSelectedPitchId: (state, action: PayloadAction<number | string | any>) => {
      state.selectedPitchId = action.payload;
    },
  },
});

export const { setStatistics, setRevenueComparison, setDateTypeFilter, setSelectedPitchId } =
  ReportingSlice.actions;

export default ReportingSlice.reducer;
