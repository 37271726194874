import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Wallet } from 'src/services/wallet/getWalletBalance';

interface StateType {
  wallet: Wallet | undefined;
}

const initialState: StateType = {
  wallet: undefined,
};

export const WalletSlice = createSlice({
  name: 'Wallet',
  initialState,
  reducers: {
    setWallet: (state, action: PayloadAction<Wallet>) => {
      state.wallet = action.payload;
    },
  },
});

export const { setWallet } = WalletSlice.actions;

export default WalletSlice.reducer;
