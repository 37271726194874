import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, GetStadiumDetailsBody>({
    query: (body) => ({
      url: `/v3/pitch-owner-app/stadiums/${body.stadiumId}`,
      method: 'Get',
    }),
  });

export interface GetStadiumDetailsBody {
  stadiumId: number;
}
