export interface Stadium {
  id: number;
  main_currency: number;
  main_currency_label: string;
  main_currency_label_ar: string;
  phone_code: string;
  support_malaeb_live: boolean;
  supported_payment_methods: string[];
  title: string;
  title_ar: string;
  name: string;
}
export interface Pitch {
  id: number;
  is_visible: number;
  lable: string;
  lable_ar: string;
  pitch_size: number;
  prices: Price[];
  sport_type: number[];
}

export interface ModifiedPitch extends Pitch {
  femaleFriendlyTimes: Price[];
}

export interface Price {
  id: number;
  day: number;
  pitch_id: number;
  price: number;
  price_60: number;
  price_90: number;
  price_120: number;
  valid_from: string;
  female_friendly: number;
  online_payment_only: number;
  upfront_available: number;
  valid_to: string;
  updated_at: string;
}

export interface DayOff {
  id?: number;
  created_at?: string;
  end_at: string;
  end_time_at: string;
  pitch_ids: number[];
  pitch_names?: string;
  stadium_id?: number;
  start_at: string;
  start_time_at: string;
  updated_at?: string;
  stadiumId: number | string;
}

export interface PitchInStadiumManagement {
  id?: number;
  is_visible: number;
  lable: string;
  lable_ar: string;
  pitch_size: number | string;
  sport_type: number;
  prices: Price[];
}

export interface StadiumDetailsInterface {
  id?: number;
  closing_time: string;
  opening_time: string;
  is_support_split_payment: boolean | number;
  split_payment_policy_hours: number;
  supported_payment_methods?: string[];
  title: string;
  title_ar: string;
  upfront_payment: boolean | number;
  upfront_price: number;
  daysoff?: DayOff[];
  pitches?: PitchInStadiumManagement[];
  stadiumId: number | string;
}

export enum StadiumCurrency {
  TND = 10,
}
