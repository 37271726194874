import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { GetStadiumDetailsBody } from './getStadiumDetails';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<IGetStadiumImagesResponse, GetStadiumDetailsBody>({
    query: (body) => ({
      url: `/v3/pitch-owner-app/stadiums/${body.stadiumId}/images`,
      method: 'Get',
    }),
  });

export interface IGetStadiumImagesResponse {
  data: {
    cover: null;
    gallery: {
      url: string;
      imageId: string;
    }[];
  };
}
