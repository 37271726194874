import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, PendingPaymentApprovalBody>({
    query: (body) => ({
      url: `v4/pitch-owner/wallet/${body.wallet_topup_id}/online-payments/pending-payment-approval`,
      method: 'POST',
      body,
    }),
  });

export interface PendingPaymentApprovalBody {
  wallet_topup_id: string;
}
