import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, data>({
    query: (body) => ({
      url: `/v3/pitch-owner-app/stadiums/${body?.stadiumId}/${body?.inpName}`,
      method: 'POST',
      body: body.formData,
    }),
  });

interface data {
  stadiumId: number | string;
  formData: FormData;
  inpName: string;
}
