import { api } from 'src/services';

import revenueComparison from './revenueComparison';
import statistics from './statistics';

export const authApis = api.injectEndpoints({
  endpoints: (build) => ({
    revenueComparison: revenueComparison(build),
    statistics: statistics(build),
  }),
  overrideExisting: true,
});

export const { useRevenueComparisonMutation, useStatisticsMutation } = authApis;
