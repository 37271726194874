import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, data>({
    query: (body) => ({
      url: `/v3/pitch-owner-app/stadiums/${body.stadiumId}/pitches/${body?.pitchId}`,
      method: 'delete',
      body,
    }),
  });

interface data {
  pitchId: number | undefined;
  stadiumId: number;
}
