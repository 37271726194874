import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { Pitch } from 'src/types/apps/stadium';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, PitchBody>({
    query: (body) => ({
      url: `v3/pitch-owner-app/stadiums/${body.stadiumId}/pitches/${body.pitchId}`,
      method: 'POST',
      body,
    }),
  });

interface PitchBody {
  stadiumId: number;
  pitchId: number;
  data: Pitch;
}
