import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<WalletData, Body>({
    query: (body) => ({
      url: `v4/pitch-owner/stadium-wallet/${body.stadium_id}`,
      method: 'GET',
    }),
  });

export interface Wallet {
  balance: number;
  currency: {
    tag: string;
    tag_ar: string;
  };
  id: number;
}

export interface WalletData {
  data: Wallet;
}

interface Body {
  stadium_id: number;
}
