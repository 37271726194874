import { api } from 'src/services'

import login from './login'
import me from './me'
import forgetpassword from './forgetpassword'
import resetPassword from './resetPassword'

export const authApis = api.injectEndpoints({
  endpoints: build => ({
    login: login(build),
    me: me(build),
    forgetpassword: forgetpassword(build),
    resetPassword: resetPassword(build)
  }),
  overrideExisting: true,
})

export const {
  useLoginMutation,
  useLazyMeQuery,
  useForgetpasswordMutation,
  useResetPasswordMutation
} = authApis
