import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, PitchBody>({
    query: (body) => ({
      url: `v3/pitch-owner-dashboard/stadiums/${body.stadiumId}/pitches`,
      method: 'POST',
      body,
    }),
  });

interface PitchBody {
  stadiumId: number;
  is_visible: number;
  lable: string;
  lable_ar: string;
  pitch_size: string;
  prices: {
    day: number;
    female_friendly: number;
    price: number;
    price_60: string;
    price_90: string;
    price_120: string;
    valid_from: string;
    valid_to: string;
  }[];
  sport_type: number;
}

export interface Pitch {
  is_visible: boolean;
  lable: string;
  lable_ar: string;
  pitch_size: string;
  prices: {
    day: number;
    female_friendly: boolean;
    price: number;
    price_60: string;
    price_90: string;
    price_120: string;
    valid_from: string;
    valid_to: string;
  }[];
  sport_type: number;
}

export enum Days {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}
