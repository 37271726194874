import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<DaysOffData, DayOffBody>({
    query: (body) => ({
      url: `/v4/pitch-owner-dashboard/stadiums/${body.stadiumId}/daysoff?page=${body.page}&limit=${body.limit}`,
      method: 'Get',
    }),
  });

export interface DaysOffData {
  data: {
    id: number;
    pitch_ids: number[];
    start_at: string;
    end_at: string;
    start_time_at: string;
    end_time_at: string;
    created_at: string | null;
    updated_at: string | null;
  }[];
  meta: {
    total: number;
  };
}

interface DayOffBody {
  stadiumId: number | undefined;
  page: number;
  limit: number;
}
