import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TournamentDetailsData } from 'src/services/tournaments/getTournamentDetails';

interface StateType {
  selectedTournament: TournamentDetailsData | undefined;
}

const initialState: StateType = {
  selectedTournament: undefined,
};

export const TournamentSlice = createSlice({
  name: 'Tournament',
  initialState,
  reducers: {
    setSelectedTournament: (state, action: PayloadAction<TournamentDetailsData>) => {
      state.selectedTournament = action.payload;
    },
  },
});

export const { setSelectedTournament } = TournamentSlice.actions;

export default TournamentSlice.reducer;
