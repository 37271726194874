import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, TopUpBody>({
    query: (body) => ({
      url: `v4/pitch-owner/wallet/topup`,
      method: 'POST',
      body,
    }),
  });

export interface TopUpBody {
  amount: number;
  payment_method: string;
}

export enum PaymentMethods {
  Cash = '1',
  CreditCard = '5',
  ApplePay = '11',
}
