import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<WalletData, void>({
    query: () => ({
      url: `v4/pitch-owner/wallet/balance`,
      method: 'GET',
    }),
  });

export interface Wallet {
  balance: number;
  currency: {
    tag: string;
    tag_ar: string;
  };
  id: number;
}

export interface WalletData {
  data: Wallet;
}
