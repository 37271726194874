import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, {}>({
    query: (body) => ({
      url: `v4/pitch-owner/statistics/revenue-comparison`,
      method: 'POST',
      body,
    }),
  });

export interface RevenueData {
  month: string;
  count_user_made: number;
  count_owner_made: number;
  total_amount_paid_user_made: number;
  total_amount_paid_owner_made?: number;
}
