import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, SendBody>({
    query: body => ({
      url: `v4/pitch-owner/stadium-wallet/${body.stadium_id}/transfer/${body.user_id}`,
      method: 'POST',
      body,
    }),
  })

export interface SendBody {
  stadium_id: number
  user_id: number
  amount: number
  description: string
}
