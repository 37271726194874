import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { StadiumDetailsInterface } from 'src/types/apps/stadium';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, StadiumDetailsInterface>({
    query: (body) => ({
      url: `/v3/pitch-owner-app/stadiums/${body?.stadiumId}`,
      method: 'POST',
      body,
    }),
  });
