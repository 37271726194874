import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, {}>({
    query: (body) => ({
      url: `/v4/pitch-owner/statistics`,
      method: 'POST',
      body,
    }),
  });

export interface StatisticsResponse {
  total_revenue: TotalRevenueDetails;
  total_bookings: number;
  total_bookings_revenue: number;
  total_bookings_revenue_percentage: number;
  total_bookings_revenue_percentage_change: number;
  total_bookings_revenue_percentage_change_percentage: number;
  booking_growth: BookingGrowth;
  booking_utilization: number;
  booking_count_by_match_duration: BookingCountByMatchDuration[];
  booking_count_by_utilization_chart: BookingCountByUtilizationChart[];
  booking_count_by_weekday: BookingCountByWeekday[];
}

export interface BookingGrowth {
  count: number;
  growth: number;
}

export interface BookingCountByMatchDuration {
  value: number;
  name: string;
  payment_order_duration: number;
}

export interface BookingCountByUtilizationChart {
  count: number;
  day_name: string;
  count_00_00: number;
  count_01_00: number;
  count_02_00: number;
  count_03_00: number;
  count_04_00: number;
  count_05_00: number;
  count_06_00: number;
  count_07_00: number;
  count_08_00: number;
  count_09_00: number;
  count_10_00: number;
  count_11_00: number;
  count_12_00: number;
  count_13_00: number;
  count_14_00: number;
  count_15_00: number;
  count_16_00: number;
  count_17_00: number;
  count_18_00: number;
  count_19_00: number;
  count_20_00: number;
  count_21_00: number;
  count_22_00: number;
  count_23_00: number;
}

export interface BookingCountByWeekday {
  count: number;
  day: number;
  name: string;
}

export interface TotalRevenueDetails {
  count_total: number;
  total_amount_paid: number;
}
