import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
    build.mutation<LoginResponse, LoginBody>({
        query: (body) => ({
            url: 'v4/pitch-owner/auth/password/reset',
            method: 'POST',
            body,
        }),
    });

interface LoginBody {
    hash: string;
    password: string;
    password_confirmation: string;
}

interface LoginResponse {
    data: {
        message: string;
    };
}
