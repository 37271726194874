import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { DayOff } from 'src/types/apps/stadium';

export default (build: EndpointBuilder<any, any, any>) =>
  build.mutation<Response, DayOff>({
    query: (body) => ({
      url: `/v4/pitch-owner/stadiums/${body.stadiumId}/daysoff`,
      method: 'POST',
      body,
    }),
  });
