import { api } from 'src/services';

import stadiums from './stadiums';
import pitches from './pitches';
import getSportsType from './getSportsType';
import getStadiumDetails from './getStadiumDetails';
import updateStadiumData from './updateStadiumData';
import getStadiumImages from './getStadiumImages';
import deleteGalleryImage from './deleteGalleryImage';
import updateStadiumCover from './updateStadiumCover';
import getDaysOff from './getDaysOff';
import deleteDaysOff from './deleteDaysOff';
import addNewDayOff from './addNewDayOff';
import deletePitch from './deletePitch';
import addPitch from './addPitch';
import updatePitch from './updatePitch';

export const authApis = api.injectEndpoints({
  endpoints: (build) => ({
    fetchStadiums: stadiums(build),
    fetchPitches: pitches(build),
    getSportTypes: getSportsType(build),
    getStadiumDetails: getStadiumDetails(build),
    updateStadiumData: updateStadiumData(build),
    getStadiumImages: getStadiumImages(build),
    deleteGalleryImage: deleteGalleryImage(build),
    updateStadiumCover: updateStadiumCover(build),
    getDaysOff: getDaysOff(build),
    deleteDaysOff: deleteDaysOff(build),
    addNewDayOff: addNewDayOff(build),
    deletePitch: deletePitch(build),
    addPitch: addPitch(build),
    updatePitch: updatePitch(build),
  }),
  overrideExisting: true,
});

export const {
  useLazyFetchStadiumsQuery,
  useLazyFetchPitchesQuery,
  useLazyGetSportTypesQuery,
  useGetStadiumDetailsMutation,
  useUpdateStadiumDataMutation,
  useGetStadiumImagesMutation,
  useDeleteGalleryImageMutation,
  useUpdateStadiumCoverMutation,
  useGetDaysOffMutation,
  useDeleteDaysOffMutation,
  useAddNewDayOffMutation,
  useDeletePitchMutation,
  useAddPitchMutation,
  useUpdatePitchMutation,
} = authApis;
