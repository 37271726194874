import React, { useState, createContext, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  SxProps,
  Box,
} from '@mui/material';

interface AlertDialogProps {
  title: string;
  message: string;
  buttons: Array<{
    text: string;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    onClick?: () => void;
  }>;
  additionalContent?: React.ReactNode;
  contentSx?: SxProps;
}

const AlertDialogContext = createContext<any>(null);

export const AlertDialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<AlertDialogProps | null>(null);

  const openDialog = (props: AlertDialogProps) => {
    setDialogProps(props);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <AlertDialogContext.Provider value={openDialog}>
      {children}
      <Dialog
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ ...dialogProps?.contentSx }}>
          <DialogTitle id="alert-dialog-title" sx={{ minWidth: 400 }}>
            {dialogProps?.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogProps?.message}
            </DialogContentText>
            {dialogProps?.additionalContent}
          </DialogContent>
        </Box>
        <DialogActions>
          {dialogProps?.buttons.map((button, index) => (
            <Button
              key={index}
              color={button.color || 'primary'}
              onClick={() => {
                if (button.onClick) {
                  button.onClick();
                }
                closeDialog();
              }}
            >
              {button.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </AlertDialogContext.Provider>
  );
};

export const useAlertDialog = () => {
  return useContext(AlertDialogContext);
};
