import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { IUser } from 'src/types/apps/user';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<Response, any>({
    query: () => ({
      url: '/v4/pitch-owner/auth/me',
      method: 'GET',
    }),
  });

export interface Response {
  data: IUser;
}
