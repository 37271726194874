import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<Response, Body>({
    query: (body) => ({
      url: `v4/pitch-owner/wallet/transactions?page=${body.page}&per_page=${body.per_page}`,
      method: 'Get',
    }),
  });

export interface WalletTransactions {
  id: number;
  amount: number;
  type: string;
  billable_type: string;
  currency: {
    tag: string;
    tag_ar: string;
  };
  status: string;
  status_text: string;
  payment_method: number;
  payment_method_name: string;
  created_at: string;
}

interface Body {
  page: number;
  per_page: number;
}
