import { api } from 'src/services';
import getWalletTransactions from './getWalletTransactions';
import walletTopUp from './walletTopUp';
import pendingPaymentApproval from './pendingPaymentApproval';
import applePayTopUp from './applePayTopUp';
import getWalletBalance from './getWalletBalance';
import getStadiumWallet from './getStadiumWallet';
import transferStadiumToUserWallet from './transferStadiumToUserWallet';

export const authApis = api.injectEndpoints({
  endpoints: (build) => ({
    getWalletTransactions: getWalletTransactions(build),
    walletTopUp: walletTopUp(build),
    pendingPaymentApproval: pendingPaymentApproval(build),
    applePayTopUp: applePayTopUp(build),
    getWalletBalance: getWalletBalance(build),
    getStadiumWallet: getStadiumWallet(build),
    transferStadiumToUserWallet: transferStadiumToUserWallet(build),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetWalletTransactionsQuery,
  useLazyGetWalletBalanceQuery,
  useWalletTopUpMutation,
  useApplePayTopUpMutation,
  usePendingPaymentApprovalMutation,
  useLazyGetStadiumWalletQuery,
  useTransferStadiumToUserWalletMutation,
} = authApis;
