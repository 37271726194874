import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

export default (build: EndpointBuilder<any, any, any>) =>
  build.query<Response, any>({
    query: () => ({ url: `/v2/sports-types`, method: 'GET' }),
  });

interface Response {
  data: { id: number; name_ar: string; name_en: string }[];
}
