import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GemBody } from 'src/services/gems/addGem';

interface StateType {
  gems: GemBody | undefined;
  totalPrice: GemBody | undefined;
}

const initialState: StateType = {
  gems: undefined,
  totalPrice: undefined,
};

export const GemsSlice = createSlice({
  name: 'Gems',
  initialState,
  reducers: {
    setGems: (state, action: PayloadAction<GemBody>) => {
      state.gems = action.payload;
      state.totalPrice = action.payload;
    },
  },
});

export const { setGems } = GemsSlice.actions;

export default GemsSlice.reducer;
